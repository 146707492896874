import React from 'react';
import Layout from '../components/Layout/Layout';
import * as styles from './404.module.css';
import Eyes from '../assets/eyes.inline.svg';

const Page404 = () => (
  <Layout currentPage={{ name: '404' }}>
    <div className={styles.main}>
      {/* <Eyes/> */}
      <span>404</span>
    </div>
  </Layout>
);

export default Page404;
